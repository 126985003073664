import { Link } from "gatsby"
import React from "react"
import * as style from "../sass/modules/navigation.module.sass"

function mobileNavToggle() {
  const navItems = document.getElementById('navigation');
  const body = document.body;
  if (navItems.className === style.navigation) {
    navItems.className = style.navigation + ' ' + style.active;
    body.className = 'active';
  } else {
    navItems.className = style.navigation;
    body.className = ' ';
  }
}

function closeNav() {
  const body = document.body;
  body.className = ' ';
}


const Navigation = () => (
  <nav className={style.navigation} id="navigation">
    <Link to="/" className={style.logo} onClick={closeNav}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583.096 250">
        <g transform="translate(-880 -506.128)">
          <path d="M24.059,0V4.929H10.327v38.8H4.537V4.929H-9.195V0Z" transform="translate(997.568 544.714)" />
          <path d="M0,17.729v-3.2c0-13.359,5.606-21.3,16.072-21.3,9.117,0,14.289,4.991,15.4,15.4l-5.732.922c-.492-7.146-3.692-11.4-9.725-11.4-6.839,0-10.1,4.991-10.1,16.075V17.36c0,11.085,3.512,16.752,10.1,16.752,6.094,0,9.233-4.246,9.725-11.453l5.667,1.172c-.922,8.618-5.725,15.211-15.826,15.211C5.175,39.041,0,31.1,0,17.729" transform="translate(989.265 612.001)" />
          <path d="M0,23.3l5.544-1.114c.987,5.24,3.942,9.92,10.593,9.92,4.126,0,8.925-1.421,8.925-6.958,0-5.794-3.631-7.146-7.077-8.068l-5.851-1.541C5.974,13.875,1.356,10.179,1.356,3.409,1.356-3.061,6.774-8.9,15.272-8.9c8.007,0,14.473,3.32,15.337,12.253l-5.483,1.6c-.557-6.9-4.987-9.056-9.667-9.056-6.4,0-8.253,3.758-8.253,7.02,0,3.884,3.016,6.343,6.712,7.392l5.6,1.476c5.851,1.541,11.4,4.123,11.4,12.564,0,6.162-4.314,12.564-14.719,12.564C5.667,36.909,1.049,30.507,0,23.3" transform="translate(989.541 675.69)" />
          <path d="M90.436,0a125,125,0,1,0,125,125,125.142,125.142,0,0,0-125-125M31.675,125c0-66.807,26.361-121.152,58.761-121.152S149.2,58.193,149.2,125,122.836,246.152,90.436,246.152,31.675,191.807,31.675,125M69.547,242.821C29.99,230.1.48,182.119.48,125S29.99,19.9,69.547,7.179C45.266,24.39,27.828,70.695,27.828,125s17.439,100.61,41.72,117.821M153.045,125c0-54.305-17.439-100.61-41.72-117.821C150.882,19.9,180.392,67.881,180.392,125s-29.51,105.1-69.068,117.821c24.281-17.211,41.72-63.516,41.72-117.821m-183.761,0A121.353,121.353,0,0,1,55.595,8.976C21.079,27.449-3.368,72.478-3.368,125s24.447,97.551,58.963,116.024A121.353,121.353,0,0,1-30.716,125M125.277,241.024c34.516-18.473,58.963-63.5,58.963-116.024S159.793,27.449,125.277,8.976a121.138,121.138,0,0,1,0,232.048" transform="translate(914.564 506.128)" />
          <path d="M0,3.564H13.721V42.325H19.5V3.564H33.225V-1.362H0Z" transform="translate(1162.534 546.075)" />
          <path d="M4.139,17.18H20.873V37.117H26.6V-6.566H20.873v18.95H4.139V-6.566H-1.582V37.117H4.139Z" transform="translate(1204.831 551.28)" />
          <path d="M18.784,28.043H-1.461V12.97H17.3v-4.8H-1.461V-5.792H18.353v-4.926H-7.179V32.969H18.784Z" transform="translate(1250.034 555.431)" />
          <path d="M11.575,3.558c6.029,0,9.226,4.249,9.717,11.388l5.725-.922C25.911,3.627,20.743-1.36,11.637-1.36,1.178-1.36-4.424,6.575-4.424,19.923v3.2c0,13.356,5.172,21.29,15.569,21.29,10.09,0,14.885-6.586,15.808-15.2l-5.66-1.172c-.492,7.2-3.627,11.443-9.717,11.443-6.582,0-10.09-5.66-10.09-16.733V19.616c0-11.074,3.258-16.057,10.09-16.057" transform="translate(1167.88 606.529)" />
          <path d="M11.528,33.114c10.647,0,15.934-7.935,15.934-20.914V8.258c0-12.98-5.287-20.914-15.934-20.914S-4.406-4.721-4.406,8.258V12.2c0,12.98,5.291,20.914,15.934,20.914M1.561,8.258c0-10.954,3.139-16,9.967-16S21.5-2.7,21.5,8.258V12.2c0,10.943-3.139,15.988-9.967,15.988S1.561,23.144,1.561,12.2Z" transform="translate(1205.809 617.828)" />
          <path d="M19.362,3.564V-1.362H-7.4V42.325h5.718V22.453H17.7V17.527H-1.682V3.564Z" transform="translate(1250.871 607.574)" />
          <path d="M0,31.6H5.721V11.732H25.1V6.806H5.721V-7.157H26.762v-4.922H0Z" transform="translate(1281.599 618.294)" />
          <path d="M18.784,28.043H-1.461V12.97H17.3v-4.8H-1.461V-5.792H18.353v-4.926H-7.179V32.969H18.784Z" transform="translate(1326.294 616.93)" />
          <path d="M18.784,28.043H-1.461V12.97H17.3v-4.8H-1.461V-5.792H18.353v-4.926H-7.179V32.969H18.784Z" transform="translate(1364.423 616.93)" />
          <path d="M14.111,14.953l-5.6-1.472c-3.692-1.049-6.7-3.5-6.7-7.388,0-3.255,1.844-7.009,8.246-7.009,4.672,0,9.1,2.152,9.66,9.045l5.475-1.6C24.324-2.4,17.865-5.715,9.865-5.715c-8.491,0-13.9,5.841-13.9,12.3,0,6.766,4.611,10.459,10.763,12.119l5.848,1.537c3.443.926,7.074,2.275,7.074,8.065,0,5.533-4.8,6.951-8.918,6.951-6.643,0-9.6-4.676-10.582-9.909l-5.54,1.11c1.049,7.2,5.66,13.6,16.18,13.6,10.4,0,14.708-6.4,14.708-12.553,0-8.43-5.54-11.016-11.385-12.553" transform="translate(1168.911 672.384)" />
          <path d="M0,3.564H13.721V42.325H19.5V3.564H33.225V-1.362H0Z" transform="translate(1200.664 669.074)" />
          <path d="M10.149,0-3.879,43.683h5.66l4-12.611H20.977l4.061,12.611H30.7L16.673,0ZM7.318,26.147c2.77-8.669,4.864-15.931,6.032-21.1h.123c1.168,5.172,3.2,12.433,5.967,21.1Z" transform="translate(1242.055 667.713)" />
          <path d="M16.246,22.258c0,1.6,0,3.2.123,4.676h-.123a49.171,49.171,0,0,0-2.09-5.729L1.3-8.507h-7.5V35.18H-.488V2.321A36.837,36.837,0,0,0-.611-1.426h.184S.5,1.337,1.541,3.738L15.262,35.18h6.709V-8.507H16.246Z" transform="translate(1285.717 676.219)" />
          <path d="M9.257,0h-12.8V43.683H9.134c11.5,0,18.7-9.414,18.7-21.905C27.839,9.168,21.253,0,9.257,0M9.073,38.888H2.183V4.8H9.2c8.488,0,12.734,7.7,12.734,16.983,0,9.045-4.615,17.11-12.857,17.11" transform="translate(1320.87 667.713)" />
          <path d="M10.149,0-3.879,43.683h5.66l4-12.611H20.977l4.061,12.611H30.7L16.673,0ZM7.318,26.147c2.77-8.669,4.864-15.931,6.032-21.1h.123c1.168,5.172,3.2,12.433,5.967,21.1Z" transform="translate(1356.444 667.713)" />
          <path d="M20.832,9.218C20.832,1.1,15.48-3.523,6.066-3.523H-7.962V40.164h5.725V21.89h8l10.09,18.274h6.34l-10.647-19.2c5.844-1.541,9.291-5.848,9.291-11.75m-23.07,8.115V1.276H5.82c5.844,0,8.983,2.828,8.983,8.061,0,5.164-3.139,8-8.983,8Z" transform="translate(1402.103 671.235)" />
          <path d="M9.257,0h-12.8V43.683H9.134c11.5,0,18.7-9.414,18.7-21.905C27.839,9.168,21.253,0,9.257,0M9.073,38.888H2.18V4.8H9.2c8.488,0,12.734,7.7,12.734,16.983,0,9.045-4.615,17.11-12.857,17.11" transform="translate(1435.258 667.713)" />
        </g>
      </svg>
    </Link>
    <ul>
      <li><Link className="btn" to="/about" activeClassName="activeBtn" onClick={closeNav}>About Us</Link></li>
      <li><Link className="btn" to="/services" activeClassName="activeBtn" onClick={closeNav}>Services</Link></li>
      <li><Link className="btn" to="/clients" activeClassName="activeBtn" onClick={closeNav}>Clients</Link></li>
      <li><Link className="btn" to="/contact" activeClassName="activeBtn" onClick={closeNav}>Contact</Link></li>
    </ul>
    <div className={style.primaryBtnWrapper}>
      <Link className="btn" to="/top-secret" activeClassName="activeBtn" onClick={closeNav}>Top Secret</Link>
      <svg onClick={mobileNavToggle} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" className={style.openIcon}>
        <g id="Group_46" data-name="Group 46" transform="translate(208 1506)">
          <rect id="Rectangle_8" data-name="Rectangle 8" width="50" height="50" transform="translate(-208 -1506)" fill="#fff" opacity="0" />
          <line id="Line_1" data-name="Line 1" x2="34" transform="translate(-200 -1481)" fill="none" strokeWidth="1" />
          <line id="Line_2" data-name="Line 2" x2="34" transform="translate(-200 -1491)" fill="none" strokeWidth="1" />
          <line id="Line_3" data-name="Line 3" x2="34" transform="translate(-200 -1471)" fill="none" strokeWidth="1" />
        </g>
      </svg>
      <svg onClick={mobileNavToggle} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" className={style.closeIcon}>
        <g id="Group_47" data-name="Group 47" transform="translate(208 1506)">
          <rect id="Rectangle_8" data-name="Rectangle 8" width="50" height="50" transform="translate(-208 -1506)" fill="#fff" opacity="0" />
          <line id="Line_2" data-name="Line 2" x2="34" transform="translate(-195.021 -1468.979) rotate(-45)" fill="none" strokeWidth="1" />
          <line id="Line_3" data-name="Line 3" x2="34" transform="translate(-195.021 -1493.021) rotate(45)" fill="none" strokeWidth="1" />
        </g>
      </svg>
    </div>
  </nav>
)

export default Navigation
